<!--
 * @Author: LazyQ
 * @Date: 2020-04-14 16:49:10
 * @LastEditTime: 2020-04-14 16:53:30
 * @LastEditors: LazyQ
 -->
 <style lang="less" scoped>
.info-view {
  display: flex;
  flex-direction: row;
}
.tab-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}
.tab-subtitle {
  font-size: 14px;
  margin-top: 3px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #818182;
}
</style>
<template>
  <div class="info-view">
    <div class="tab-title" v-if="cusTitle && cusTitle.length > 0">{{cusTitle}}</div>
    <div class="tab-title" v-else>{{title}}</div>
    <div class="tab-subtitle">
      <slot name="custom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabTitle",
  data() {
    return {
    };
  },
  props: {
    cusTitle: {
      type: String,
      default: ''
    }
  },
  created() {
    this.title = this.$route.meta.title;
  }
};
</script>